














import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { i18n } = useContext()
    const locales = computed(() => i18n.locales)
    const selectLocale = (code: string) => {
      i18n.setLocale(code)
    }
    const activeLocale = computed(() => i18n.locale)

    return { locales, selectLocale, activeLocale }
  },
})
